import React, { useEffect, useState } from "react";
import Image from "next/image";
import { useTheme } from "next-themes";

interface PlutoLogoProps {
  className?: string;
}

const PlutoLogo = ({ className = "" }) => {
  const [imageSrc, setImageSrc] = useState("/pluto-logo.svg");
  const { theme, systemTheme } = useTheme();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!mounted) return;

    // Determine the effective theme considering 'system' preference
    const effectiveTheme = theme === "system" ? systemTheme : theme;

    // Update logo based on the effective theme
    const logoSrc =
      effectiveTheme === "dark" ? "/pluto-logo.svg" : "/pluto-logo-dark.svg";
    setImageSrc(logoSrc);
  }, [theme, systemTheme, mounted]);

  if (!mounted) return null;

  return (
    <div>
      <Image
        height={250}
        width={250}
        className={`h-6 w-auto ${className}`}
        src={imageSrc}
        alt="pluto-logo"
      />
    </div>
  );
};

export default PlutoLogo;
